// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WebshopView-webshop{font-family:v-sans,sans-serif;max-width:var(--3c3371d8);margin:0 auto}.WebshopView-webshop.WebshopView-loading{text-align:center}.WebshopView-webshop .WebshopView-title{text-decoration:none;color:inherit}.WebshopView-webshop .WebshopView-footer{display:flex;justify-content:flex-end}.WebshopView-webshop .WebshopView-footer .WebshopView-powered-by{display:flex;align-items:center;gap:5px}.WebshopView-webshop .WebshopView-footer .WebshopView-powered-by span{color:var(--n-text-color);opacity:.5}.WebshopView-webshop .WebshopView-footer .WebshopView-powered-by img{height:1.5em}.WebshopView-webshop .WebshopView-header{display:none}@media screen and (max-width:1000px){.WebshopView-webshop .WebshopView-header{display:flex;justify-content:flex-end;width:100%;margin-bottom:12px}}.WebshopView-webshop .WebshopView-layout{display:flex;gap:20px}.WebshopView-webshop .WebshopView-layout .WebshopView-content{flex-grow:1}.WebshopView-webshop .WebshopView-layout .WebshopView-sidebar{flex-grow:1;flex-shrink:0;flex-basis:100%;max-width:300px}@media screen and (max-width:1000px){.WebshopView-webshop .WebshopView-layout .WebshopView-sidebar{flex-basis:0;flex-grow:0;position:absolute}}.WebshopView-webshop a{text-decoration:none;color:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webshop": "WebshopView-webshop",
	"loading": "WebshopView-loading",
	"title": "WebshopView-title",
	"footer": "WebshopView-footer",
	"powered-by": "WebshopView-powered-by",
	"poweredBy": "WebshopView-powered-by",
	"header": "WebshopView-header",
	"layout": "WebshopView-layout",
	"content": "WebshopView-content",
	"sidebar": "WebshopView-sidebar"
};
export default ___CSS_LOADER_EXPORT___;
