// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderSummary-order-summary{position:relative}.OrderSummary-order-summary .OrderSummary-position-list,.OrderSummary-order-summary .OrderSummary-summary{transition:opacity .1s linear}.OrderSummary-order-summary .OrderSummary-loading-overlay{position:absolute;inset:0;display:flex;justify-content:center;align-items:center;pointer-events:none;opacity:0;transition:opacity .1s linear}.OrderSummary-order-summary.OrderSummary-loading .OrderSummary-position-list,.OrderSummary-order-summary.OrderSummary-loading .OrderSummary-summary{opacity:.5;pointer-events:none;-webkit-user-select:none;-moz-user-select:none;user-select:none;user-focus:none}.OrderSummary-order-summary.OrderSummary-loading .OrderSummary-loading-overlay{transition-delay:.4s;opacity:1}.OrderSummary-order-summary .OrderSummary-summary{list-style:none;padding:0}.OrderSummary-order-summary .OrderSummary-summary li{display:flex;justify-content:space-between}.OrderSummary-order-summary .OrderSummary-summary li .OrderSummary-value{font-variant-numeric:tabular-nums}.OrderSummary-order-summary .OrderSummary-summary li .OrderSummary-n-divider{margin-top:10px;margin-bottom:10px}.OrderSummary-order-summary .OrderSummary-summary li.OrderSummary-total{font-weight:700}.OrderSummary-errors{color:#ff6c6c;font-style:italic;list-style:none;padding-left:0}.OrderSummary-errors li{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order-summary": "OrderSummary-order-summary",
	"orderSummary": "OrderSummary-order-summary",
	"position-list": "OrderSummary-position-list",
	"positionList": "OrderSummary-position-list",
	"summary": "OrderSummary-summary",
	"loading-overlay": "OrderSummary-loading-overlay",
	"loadingOverlay": "OrderSummary-loading-overlay",
	"loading": "OrderSummary-loading",
	"value": "OrderSummary-value",
	"n-divider": "OrderSummary-n-divider",
	"nDivider": "OrderSummary-n-divider",
	"total": "OrderSummary-total",
	"errors": "OrderSummary-errors"
};
export default ___CSS_LOADER_EXPORT___;
