import 'reflect-metadata';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from '@/App.vue';
import { createWebShopRouter } from '@/router';
import { createOptions, OffisyWebShopOptions } from '@/options';
import 'vfonts/OpenSans.css';

import de from '@/locales/de.yml';

export function createOffisyWebShop(
  rootContainer: string | HTMLElement,
  options: string | DeepPartial<OffisyWebShopOptions>
) {
  const opts = createOptions(options);

  const i18n = createI18n({
    legacy: false,
    availableLocales: ['de'],
    messages: {
      de,
    },
    locale: 'de',
  });
  createApp(App, {
    options: opts,
  })
    .use(i18n)
    .use(createWebShopRouter(opts))
    .mount(rootContainer);
}

window.createOffisyWebShop = createOffisyWebShop;

declare global {
  interface Window {
    createOffisyWebShop: typeof createOffisyWebShop;
  }
}
