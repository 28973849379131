import axios, { Axios } from 'axios';
import { inject, provide } from 'vue';
import { OffisyWebShopOptions } from '@/options';

const axiosToken = Symbol('Axios');

export function createAxios(opts: OffisyWebShopOptions) {
  const instance = axios.create({
    baseURL: `${opts.endpoint}/${opts.token}/`,
    withCredentials: false,
  });

  provide(axiosToken, instance);
  return instance;
}

export function useAxios(): Axios {
  return inject(axiosToken) as Axios;
}
