import { ConfigurableProduct } from '@/state/configuration';
import { Type } from 'class-transformer';

export type ProductCategory = 'coupon';

export class ProductImage {
  id: string;
  mimeType: string;
  isPreview: boolean;
}

export class Product {
  type: number;
  id: string;
  name: string;
  description?: string;

  @Type(() => ProductImage)
  images: ProductImage[];

  canBeConfigured(): this is ConfigurableProduct<any> {
    return false;
  }
}
