import { ref, Ref } from 'vue';
import { reactiveComputed } from '@vueuse/core';

export type LoaderOptions = {
  [key: string]: () => Promise<any>;
};

export type Loader<T extends LoaderOptions> = {
  [key in keyof T]: Ref<Awaited<T[key]> | undefined>;
} & {
  status: Ref<'loading' | 'loaded' | 'error'>;
  error: Ref<Error | undefined>;
};

export function load<T extends LoaderOptions>(load: T): Loader<T> {
  let data = {
    status: ref('loading'),
    error: ref(),
  } as unknown as Loader<T>;

  Object.keys(load).forEach(key => {
    // @ts-ignore
    data[key] = ref();
  });
  Promise.all(
    Object.entries(load).map(async ([key, loader]) => {
      const value = await loader();
      data[key].value = value;
      return value;
    })
  )
    .then(() => {
      data.status.value = 'loaded';
    })
    .catch(e => {
      data.status.value = 'error';
      data.error = e;
    });
  return data;
}

export function arrayify<T>(
  value: T | T[] | null | undefined,
  filterNull = false
): T[] {
  if (value !== undefined && value !== null) {
    if (Array.isArray(value)) {
      if (filterNull) return value.filter(v => v !== null) as T[];

      return value;
    }

    return [value];
  } else {
    return [];
  }
}

type KeysOfTypeStrict<T, U> = {
  [P in keyof T]: T[P] extends U ? (U extends T[P] ? P : never) : never;
}[keyof T];
type PickByTypeStrict<T, U> = Pick<T, KeysOfTypeStrict<T, U>>;

export function useGroup<T, K extends keyof PickByTypeStrict<T, string>, R = T>(
  values: T[],
  key: K,
  map?: (value: T) => R
): Record<string, R[]> {
  return reactiveComputed(() =>
    values
      .map(v => v[key])
      .reduce((acc, val, i) => {
        const value = map ? map(values[i]) : (values[i] as unknown as R);

        acc[val as unknown as string] = (
          acc[val as unknown as string] ?? []
        ).concat(value);
        return acc;
      }, {} as Record<string, R[]>)
  );
}
