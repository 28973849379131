export default {
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "invoiceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])}
  },
  "actions": {
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse gehen"])},
    "cart": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Einkaufswagen"])}
    },
    "confirmPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungspflichtig bestellen"])}
  },
  "cart": {
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto"])},
    "gross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutto"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Einkaufswagen ist leer"])}
  },
  "product": {
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])}
  },
  
}