import { Expose, Type } from 'class-transformer';

export class Cart {
  @Type(() => CartItem)
  @Expose()
  items: CartItem[] = [];
}

export class CartItem {
  product: string;
  quantity: number = 1;
  configuration?: any;
}
