import { inject, markRaw, provide, reactive, watch } from 'vue';
import {
  Exclude,
  instanceToPlain,
  plainToClassFromExist,
  Type,
} from 'class-transformer';
import { Product } from '@/state/product';
import * as vueRouter from 'vue-router';
import { Cart, CartItem } from '@/state/cart';
import { WebShopApi } from '@/api';
import { Axios } from 'axios';
import { OffisyWebShopOptions } from '@/options';
import { Order } from '@/state/order';
import * as uuid from 'uuid';

const stateToken = Symbol('Offisy Webshop State');

export class WebShopState {
  session = uuid.v4();

  @Type(() => Cart)
  cart = new Cart();

  @Exclude()
  router!: vueRouter.Router;

  @Exclude()
  order: Order | null = null;

  @Exclude()
  orderLoading = false;

  @Exclude()
  api: WebShopApi;

  constructor(readonly token: string) {}

  async addToCart(product: Product, configuration?: any) {
    if (product.canBeConfigured() && !configuration)
      return this.router.push({
        name: 'product',
        params: {
          id: product.id,
        },
        query: {
          configure: '1',
        },
      });

    const item = new CartItem();
    item.product = product.id;
    item.quantity = 1;
    item.configuration = configuration;

    this.cart.items.push(item);
  }

  removeFromCart(item: CartItem) {
    const index = this.cart.items.indexOf(item);
    if (index >= 0) {
      this.cart.items.splice(index, 1);
    }
  }

  async reload() {
    this.orderLoading = true;
    this.order = await this.api.loadCart(this.cart);
    this.orderLoading = false;
  }

  reset() {
    this.cart.items = [];
    if (this.order) {
      this.order.positions = [];
      this.order.net = 0;
    }
    this.session = uuid.v4();
  }
}

export function getStorageKey(token: string): string {
  return `offisy-webshop-state:${token}`;
}

export function createState(
  token: string,
  axios: Axios,
  options: OffisyWebShopOptions
): WebShopState {
  const persisted = localStorage.getItem(getStorageKey(token));
  const state = reactive(new WebShopState(token)) as unknown as WebShopState;
  try {
    if (persisted) plainToClassFromExist(state, JSON.parse(persisted));
  } catch (e) {
    console.error('Could not load persisted state', e);
  }

  watch(
    state,
    state => {
      localStorage.setItem(
        getStorageKey(token),
        JSON.stringify(instanceToPlain(state))
      );
    },
    {
      deep: true,
    }
  );

  provide(stateToken, state);
  state.router = markRaw(vueRouter.useRouter());
  state.api = new WebShopApi(axios, options);
  state.reload().then();

  watch(state.cart, () => state.reload(), { deep: true });
  return state as unknown as WebShopState;
}

export function useState(): WebShopState {
  return inject(stateToken) as WebShopState;
}
