// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingOverlayProvider-loading-overlay{position:fixed;inset:0;background-color:rgba(0,0,0,.5);z-index:10000;display:flex;justify-content:center;align-items:center;color:#fff;font-size:48px}.LoadingOverlayProvider-loading-overlay>*{animation:LoadingOverlayProvider-rotation 1.5s linear infinite}@keyframes LoadingOverlayProvider-rotation{0%{transform:rotate(0deg)}to{transform:rotate(359deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-overlay": "LoadingOverlayProvider-loading-overlay",
	"loadingOverlay": "LoadingOverlayProvider-loading-overlay",
	"rotation": "LoadingOverlayProvider-rotation"
};
export default ___CSS_LOADER_EXPORT___;
