import {
  computed,
  inject,
  provide,
  reactive,
  Ref,
  ref,
  watchEffect,
} from 'vue';
import { useLoadingBar } from 'naive-ui';
import * as uuid from 'uuid';

export function useLoading(): Ref<boolean> {
  const loading = ref(false);
  const loadingBar = useLoadingBar();

  watchEffect(() => {
    if (loading.value) {
      loadingBar.start;
    } else {
      loadingBar.finish();
    }
  });

  return loading;
}

const loadingSymbol = Symbol('loading');

export function provideLoading() {
  const loaders = reactive(new Set<string>());

  provide(loadingSymbol, loaders);

  return computed(() => loaders.size > 0);
}

export function useLoadingOverlay() {
  const loaders = inject(loadingSymbol) as Set<string>;

  return {
    start() {
      const id = uuid.v4();
      loaders.add(id);
      return () => loaders.delete(id);
    },
  };
}

export function useLoadingOverlayAsync() {
  const { start } = useLoadingOverlay();

  return async (fn: () => Promise<any>) => {
    const stop = start();
    try {
      return await fn();
    } finally {
      stop();
    }
  };
}
