import { Configurable } from '@/state/configuration/decorator';
import { ConfigurableProduct } from '@/state/configuration';
import { IsNotEmpty, MaxLength } from 'class-validator';
import TextInput from '@/state/configuration/components/TextInput.vue';

export class Coupon extends ConfigurableProduct<CouponConfiguration> {
  configurationType = CouponConfiguration;
}

export class CouponConfiguration {
  @Configurable('Empfänger', TextInput)
  @IsNotEmpty({ message: 'Bitte geben Sie einen Namen an.' })
  @MaxLength(128, { message: 'Der Name darf maximal 128 Zeichen lang sein.' })
  recipient: string = '';
}
