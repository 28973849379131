import { Product } from '@/state/product';

export abstract class ConfigurableProduct<T> extends Product {
  abstract configurationType: Constructor<T>;

  canBeConfigured() {
    return true;
  }

  defaultConfiguration(): T {
    return new this.configurationType();
  }
}
