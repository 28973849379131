import { Product, ProductCategory, ProductImage } from '@/state/product';
import { Axios } from 'axios';
import { OffisyWebShopOptions, useOptions } from '@/options';
import { useAxios } from '@/axios';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { ProductList } from '@/state/util';
import { Cart } from '@/state/cart';
import { Order } from '@/state/order';
import { Address } from '@/state/address';

export class WebShopApi {
  constructor(readonly axios: Axios, readonly opts: OffisyWebShopOptions) {}

  async getProducts(
    categories: ProductCategory[] = [],
    page: number = 0,
    perPage: number = 0
  ): Promise<ProductList> {
    const response = await this.axios.get('/products', {
      params: {
        offset: (page - 1) * perPage,
        limit: perPage,
      },
    });

    if (response.data) {
      return plainToInstance(ProductList, response.data);
    }

    throw Error('invalid response');
  }

  productImageUrl(product: Product, image: ProductImage) {
    return this.axios.getUri({
      url: `/products/${product.id}/img/${image.id}`,
    });
  }

  url(url: string) {
    return this.axios.getUri({
      url: url,
    });
  }

  async getProductById(id: string) {
    const response = await this.axios.get(`/products/${id}`);

    if (response.data) {
      return plainToInstance(ProductList, { items: [response.data] }).items[0];
    }

    throw Error('invalid response');
  }

  async loadCart(cart: Cart) {
    const response = await this.axios.post(
      '/orders/cart/preview',
      instanceToPlain(cart)
    );

    if (response.data) {
      return plainToInstance(Order, response.data);
    }

    throw Error('invalid response');
  }

  async getOrder(id: string) {
    const response = await this.axios.get(`/orders/${id}`);

    if (response.data) {
      return plainToInstance(Order, response.data);
    }

    throw Error('invalid response');
  }

  async createOrder(
    order: CreateOrderDto
  ): Promise<{ order: any; invoice: any }> {
    const response = await this.axios.post('orders', order);
    return response.data;
  }

  async getOrderPaymentStatus(id: string): Promise<OrderPaymentStatusReponse> {
    const response = await this.axios.post(`/orders/paymentstatus/${id}`);
    return response.data;
  }
}

// see backend entity Application\WebshopBundle\Entity\WebshopOrder
export type OrderPaymentStatusReponse =
  | 'payment_success'
  | 'payment_failure'
  | 'pending';

export function useApi() {
  return new WebShopApi(useAxios(), useOptions());
}

interface CreateOrderDto {
  session: string;
  cart: Cart;
  billingAddress: Address;
  email: string;
  isPaymentMethodInvoice: boolean;
}
