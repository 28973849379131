import { Type } from 'class-transformer';
import { Coupon } from '@/state/coupon';
import { Product } from '@/state/product';

export const productDiscriminatorOptions = {
  discriminator: {
    property: 'type',
    subTypes: [
      {
        name: 'coupon',
        value: Coupon,
      },
    ],
  },
  keepDiscriminatorProperty: true,
};

export class ProductList {
  @Type(() => Product, {
    ...productDiscriminatorOptions,
  })
  items: Product[];

  total: number;
}
