import { Component } from 'vue';
import TextInput from './components/TextInput.vue';

const configurablesKey = Symbol('configurables');

interface ConfigurableOptions<Props = any> {
  propertyKey: string;
  label: string;
  component: Component<Props>;
  props?: Props;
}

export function Configurable<Props = any>(
  label: string,
  component?: Component<Props>,
  props?: Props
): PropertyDecorator {
  return (target, propertyKey) => {
    if (typeof propertyKey === 'symbol') return;

    if (!component) {
      const type = Reflect.getMetadata('design:type', target, propertyKey);
      if (type === String) {
        component = TextInput as Component<Props>;
      }
    }

    if (!component)
      throw new Error(
        'Could not determine component for property ' + String(propertyKey)
      );

    const configurables = (Reflect.getMetadata(configurablesKey, target) ??
      []) as ConfigurableOptions[];
    configurables.push({ component, props, label, propertyKey });
    Reflect.defineMetadata(configurablesKey, configurables, target);
  };
}

export function getConfigurables(target: any): ConfigurableOptions[] {
  return Reflect.getMetadata(configurablesKey, target) ?? [];
}
