// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderSummaryPosition-order-position{display:flex;flex-direction:column}.OrderSummaryPosition-order-position>.OrderSummaryPosition-name{font-weight:700}.OrderSummaryPosition-order-position.OrderSummaryPosition-invalid .OrderSummaryPosition-price,.OrderSummaryPosition-order-position.OrderSummaryPosition-invalid>.OrderSummaryPosition-name{text-decoration:line-through;opacity:.5}.OrderSummaryPosition-order-position.OrderSummaryPosition-invalid .OrderSummaryPosition-quantity{opacity:.5;pointer-events:none}.OrderSummaryPosition-order-position .OrderSummaryPosition-details{display:flex;flex-direction:row;justify-content:space-between;align-items:center;gap:10px}.OrderSummaryPosition-order-position .OrderSummaryPosition-details>:first-child{flex-grow:1}.OrderSummaryPosition-order-position .OrderSummaryPosition-details .OrderSummaryPosition-quantity{width:100px;text-align:right}.OrderSummaryPosition-order-position .OrderSummaryPosition-errors{color:#ff6c6c;font-style:italic;list-style:none;padding-left:0}.OrderSummaryPosition-order-position .OrderSummaryPosition-errors li{margin:0}.OrderSummaryPosition-order-position .OrderSummaryPosition-configuration{list-style:none;padding:0;margin:0}.OrderSummaryPosition-order-position .OrderSummaryPosition-configuration li{display:flex;opacity:.75;gap:10px}.OrderSummaryPosition-order-position .OrderSummaryPosition-configuration li .OrderSummaryPosition-name{white-space:nowrap;margin-right:10px}.OrderSummaryPosition-order-position .OrderSummaryPosition-configuration li .OrderSummaryPosition-value{font-style:italic;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;flex-grow:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order-position": "OrderSummaryPosition-order-position",
	"orderPosition": "OrderSummaryPosition-order-position",
	"name": "OrderSummaryPosition-name",
	"invalid": "OrderSummaryPosition-invalid",
	"price": "OrderSummaryPosition-price",
	"quantity": "OrderSummaryPosition-quantity",
	"details": "OrderSummaryPosition-details",
	"errors": "OrderSummaryPosition-errors",
	"configuration": "OrderSummaryPosition-configuration",
	"value": "OrderSummaryPosition-value"
};
export default ___CSS_LOADER_EXPORT___;
