import { inject, provide } from 'vue';
import { plainToInstance, Type } from 'class-transformer';
import { RouteLocationNamedRaw } from 'vue-router';

const optionsToken = Symbol('Webshop Options');

export class OffisyWebShopOptions {
  useHistoryMode = false;
  endpoint = process.env.VUE_APP_WEBSHOP_ENDPOINT;
  token: string;

  title: string;

  home: string | RouteLocationNamedRaw = '/products';

  currency = new Currency();

  maxWidth: number | null = null;

  terms: string;

  rightOfWithdrawal: string;

  privacyTerms: string;

  maintenance = false;

  @Type(() => WebShopStyle)
  style = new WebShopStyle();

  isPaymentProviderValid: boolean;
  paymentProviderCheckoutName: string;
  isPayByInvoiceAllowed: boolean;
}

export class WebShopStyle {
  darkTheme = false;

  primaryColor = '#00adba';
  primaryColorHover = '#09c1ce';
  primaryColorPressed = '#00959F';
}

export class Currency {
  symbol = '€';
  separator = ',';
  type: 'PRE' | 'SUF' = 'SUF';

  format(value: number | string) {
    if (typeof value === 'string') {
      value = Number.parseFloat(value);
    }
    const asString = value.toFixed(2).replace('.', this.separator);
    if (this.type === 'PRE') {
      return `${this.symbol} ${asString}`;
    }
    return `${asString} ${this.symbol}`;
  }
}

export function createOptions(
  opts: string | DeepPartial<OffisyWebShopOptions>
) {
  if (typeof opts === 'string') {
    const options = new OffisyWebShopOptions();
    options.token = opts;
    return options;
  }
  return plainToInstance(OffisyWebShopOptions, opts ?? {});
}

export function provideOptions(options: OffisyWebShopOptions) {
  provide(optionsToken, options);
}

export function useOptions() {
  return inject(optionsToken) as OffisyWebShopOptions;
}
