// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideBar-sidebar-wrapper{position:fixed;inset:0;overflow:hidden;pointer-events:none;z-index:1000;overscroll-behavior:none}.SideBar-sidebar-wrapper.SideBar-open{pointer-events:all}.SideBar-sidebar-wrapper .SideBar-sidebar{position:absolute;right:0;max-width:300px;width:100%;top:0;bottom:0;background-color:#fff;padding:12px;box-shadow:0 0 50px rgba(0,0,0,.2);transition:transform .3s ease-out;overscroll-behavior:none}.SideBar-sidebar-wrapper .SideBar-sidebar.SideBar-swiping{transition:none}.SideBar-text-right{text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-wrapper": "SideBar-sidebar-wrapper",
	"sidebarWrapper": "SideBar-sidebar-wrapper",
	"open": "SideBar-open",
	"sidebar": "SideBar-sidebar",
	"swiping": "SideBar-swiping",
	"text-right": "SideBar-text-right",
	"textRight": "SideBar-text-right"
};
export default ___CSS_LOADER_EXPORT___;
