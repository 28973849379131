import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
import WebshopView from '@/views/WebshopView.vue';
import { OffisyWebShopOptions } from '@/options';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:token',
    component: WebshopView,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/WebshopHomeView.vue'),
      },
      {
        name: 'products',
        path: 'products',
        component: () => import('@/views/ProductListingView.vue'),
      },
      {
        name: 'product',
        path: 'products/:id',
        component: () => import('@/views/ProductView.vue'),
      },
      {
        name: 'configureProduct',
        path: 'configure/product/:id',
        component: () => import('@/views/ConfigureProductView.vue'),
      },
      {
        name: 'configureCartItem',
        path: 'configure/cart/:id',
        component: () => import('@/views/ConfigureCartItemView.vue'),
      },
      {
        name: 'checkout',
        path: 'checkout',
        component: () => import('@/views/CheckoutView.vue'),
      },
      {
        name: 'order',
        path: 'order/:id/:status',
        components: {
          default: () => import('@/views/OrderView.vue'),
          sidebar: () => import('@/views/OrderSidebarView.vue'),
        },
      },
      {
        name: 'orderbyinvoice',
        path: 'order/:id',
        components: {
          default: () => import('@/views/OrderView.vue'),
          sidebar: () => import('@/views/OrderSidebarView.vue'),
        },
      },
      {
        name: 'agb',
        path: 'agb',
        component: () => import('@/views/AGBView.vue'),
      },
      {
        name: 'widerrufsrecht',
        path: 'widerrufsrecht',
        component: () => import('@/views/RightOfWithdrawalView.vue'),
      },
      {
        name: 'privacy',
        path: 'datenschutz',
        component: () => import('@/views/PrivacyView.vue'),
      },
      {
        name: 'finishcheckout',
        path: 'finishcheckout/:orderid',
        component: () => import('@/views/FinishCheckoutView.vue'),
      },
    ],
  },
];

export function createWebShopRouter(opts: OffisyWebShopOptions) {
  if (opts.useHistoryMode)
    return createRouter({
      history: createWebHistory('/'),
      routes,
    });
  else {
    const router = createRouter({
      history: createMemoryHistory(),
      routes: [
        {
          path: '/',
          redirect: `/${opts.token}`,
        },
        ...routes,
      ],
    });
    router.replace('/' + opts.token);
    return router;
  }
}
