import { Type } from 'class-transformer';
import { Product } from '@/state/product';
import { productDiscriminatorOptions } from '@/state/util';
import { VAT } from '@/state/vat';

export class Order {
  @Type(() => OrderPosition)
  positions = [];

  id?: string;

  net: number;

  @Type(() => VatBucket)
  vatBuckets: VatBucket[];

  isValid?: boolean;
  orderNumber: any;
}

export class VatBucket {
  amount: number;
  @Type(() => VAT)
  vat: VAT;
}

export class OrderPosition {
  @Type(() => Product, {
    ...productDiscriminatorOptions,
  })
  product?: Product;

  errors?: string[];

  quantity: number;
  unit?: string;

  grossPerItem: number;
  netPerItem: number;

  grossTotal: number;
  netTotal: number;

  get isValid() {
    return (this.errors?.length ?? 0) === 0;
  }
}
